import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TextPlugin } from 'gsap/TextPlugin';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, DocumentIcon, ArrowPathIcon, ArrowTopRightOnSquareIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

gsap.registerPlugin(ScrollTrigger, TextPlugin);

const AdvancedPortfolio = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const cursorRef = useRef(null);
  const heroTextRef = useRef(null);
  const containerRef = useRef(null);
  const sectionRef = useRef(null);
  const blogRefs = useRef([]);
  const menuRef = useRef(null);
  const researchRef = useRef(null);

  useEffect(() => {
    // Custom cursor animation
    const cursor = cursorRef.current;
    const moveCursor = (e) => {
      gsap.to(cursor, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.5,
        ease: "power2.out"
      });
    };
    window.addEventListener('mousemove', moveCursor);
    window.SubstackFeedWidget = {
      substackUrl: "mayankworks.substack.com",
      posts: 1,
      colors: {
        primary: "#FFFFFF",
        secondary: "#FFFFFF",
        background: "#FFFFFF00",
      },
      layout: "right",
    };

    const script = document.createElement("script");
    script.src = "https://substackapi.com/embeds/feed.js";
    script.async = true;
    document.body.appendChild(script);

    // Magnetic effect on links
    const links = document.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('mouseenter', () => {
        gsap.to(cursor, {
          scale: 3,
          duration: 0.3
        });
      });
      link.addEventListener('mouseleave', () => {
        gsap.to(cursor, {
          scale: 1,
          duration: 0.3
        });
      });
    });

    const SubstackFeed = () => {
      useEffect(() => {
        // Set the Substack feed widget configuration
        window.SubstackFeedWidget = {
          substackUrl: "mayankworks.substack.com",
          posts: 1,
          layout: "right",
        };
    
        // Dynamically create and append the script tag
        const script = document.createElement("script");
        script.src = "https://substackapi.com/embeds/feed.js";
        script.async = true;
        document.body.appendChild(script);
    
        // Cleanup: remove script and widget on component unmount
        return () => {
          document.body.removeChild(script);
          delete window.SubstackFeedWidget;
        };
      }, []);
    };

    // Hero text animation
    const typingText = gsap.timeline({ repeat: -1 });
    const words = ["Software Engineer", "Innovator", "Problem Solver"];

    words.forEach((word, index) => {
      const prevWord = index === 0 ? words[words.length - 1] : words[index - 1];
      
      typingText
        .to(heroTextRef.current, {
          duration: prevWord.length * 0.1,
          text: {
            value: "",
            type: "diff",
          },
          ease: "none"
        })
        .to({}, { duration: 0.3 })
        .to(heroTextRef.current, {
          duration: word.length * 0.1,
          text: {
            value: word,
            type: "diff",
          },
          ease: "none"
        })
        .to({}, { duration: 1 });
    });

    // Mobile menu animation
    if (menuRef.current) {
      gsap.set(menuRef.current, { yPercent: -100 });
    }

    // Scroll-triggered animations
    gsap.from(".project-card", {
      scrollTrigger: {
        trigger: ".project-card",
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
      y: 100,
      opacity: 0,
      stagger: 0.3,
    });

    return () => {
      window.removeEventListener('mousemove', moveCursor);
      typingText.kill();
    };
  }, []);

  // Handle mobile menu animation
  useEffect(() => {
    if (menuRef.current && isMenuOpen) {
      gsap.fromTo(menuRef.current,
        {
          opacity: 0,
          yPercent: -100
        },
        {
          opacity: 1,
          yPercent: 0,
          duration: 0.6,
          ease: "power3.out"
        }
      );
    } else if (menuRef.current) {
      gsap.to(menuRef.current, {
        opacity: 0,
        yPercent: -100,
        duration: 0.4,
        ease: "power3.in"
      });
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const ResearchPaperCard = ({ title, authors, publication, link }) => {
    return (
      <div className="p-6 bg-gradient-to-r from-gray-800 via-gray-900 to-black rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 max-w-md">
        <h2 className="text-lg font-semibold text-indigo-400">{title}</h2>
        <p className="mt-2 text-sm text-gray-400">{authors}</p>
        <p className="mt-1 text-xs text-gray-500 italic">{publication}</p>
        <div className="mt-4 flex items-center justify-between">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-indigo-400 hover:text-indigo-500 transition-colors"
          >
            <ArrowTopRightOnSquareIcon className="h-5 w-5 mr-2" />
            View Paper
          </a>
        </div>
      </div>
    );
  };
  const papers = [
    {
      title: "Canonical Service: Streaming data in real time",
      authors: "Mayank Sharma",
      publication: "Tesco Media, Dunnhumby*",
      link: "https://drive.google.com/file/d/1Ej3fZ0rNkIl_JW_9AYIOZJi3g_438jzv/view?usp=drive_link",
    },
    {
      title: "An Empirical Analysis of Different Object Detection Algorithms for Face Recognition",
      authors: "Mayank Sharma, Dr. Kimmi Verma",
      publication: "IEEE",
      link: "https://drive.google.com/file/d/1V7hnSB3gwh18aiil81yMUrtmcGREYcDr/view?usp=sharing",
    },
  ];

  const BlogsCard = ({ title, description, link }) => {
    return (
      <div className="p-6 bg-gradient-to-r from-gray-800 via-gray-900 to-black rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2 max-w-md">
        <h2 className="text-lg font-semibold text-indigo-400">{title}</h2>
        <p className="mt-2 text-sm text-gray-400">{description}</p>
        <div className="mt-4 flex items-center justify-between">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-indigo-400 hover:text-indigo-500 transition-colors"
          >
            <ArrowTopRightOnSquareIcon className="h-5 w-5 mr-2" />
            View Article
          </a>
        </div>
      </div>
    );
  };

  const blogs = [
    {
      title: "Kitaabkhana- System Design",
      description: "This study demonstrates the design and implementation of a scalable, secure, and feature-rich book management web application",
      link: "https://mayankworks.substack.com/p/system-design-explained-kitaabkhana"
    },
    {
      title: "Notebook converter whitepaper",
      description: "The Text-to-Jupyter Notebook Converter exemplifies a modular and extensible approach to generating Jupyter Notebooks from unstructured text.",
      link: "https://medium.com/@mayanksharma_68675/text-to-notebook-converter-9e395dec1836"
    },
    {
      title: "The Frappe Framework",
      description: "In this article, we'll delve into the intricate architecture of the Frappe Framework, its advantages, and how it stands out among other frameworks.",
      link: "https://medium.com/@mayanksharma_68675/the-frappe-framework-a-comprehensive-exploration-65df60fc7e13"
    },
    {
      title: "Understanding Design Patterns",
      description: "In this blog post, we will dive deep into three core types of design patterns — Creational, Structural, and Behavioral — by using the example of a car manufacturing company",
      link: "https://mayankworks.substack.com/p/understanding-design-patterns-through"
    },
    {
      title: "The Engineering Behind Roast My Resume",
      description: "This blog dives into the engineering behind the app, showing how creative problem-solving can lead to impactful results.",
      link: "https://medium.com/@mayanksharma_68675/the-engineering-behind-roast-my-resume-simplicity-meets-impact-1ed0e0266c6d"
    },
    {
      title: "Engineering Depths of Edge Computing",
      description: "This detailed analysis dives into the computational frameworks, architectural nuances, optimization strategies, and unsolved challenges that make edge computing a transformative technology.",
      link: "https://medium.com/@mayanksharma_68675/the-computational-and-engineering-depths-of-edge-computing-ff6946bc9a56"
    }
  ]

  return (
    <div ref={containerRef} className="bg-[#0a0a0a] text-white min-h-screen overflow-hidden">
      {/* Custom Cursor */}
      <div ref={cursorRef} className="hidden md:block fixed w-4 h-4 bg-indigo-500 rounded-full pointer-events-none mix-blend-difference z-50" />

      {/* Navigation */}
      <nav className="fixed w-full z-40 top-0 p-6 bg-white/10 backdrop-blur-md">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <span className="text-2xl font-bold tracking-wider">M.S</span>
          
          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-8 text-sm tracking-widest">
            <a href="#projects" className="hover:text-indigo-400 transition-colors">PROJECTS</a>
            <a href="#experience" className="hover:text-indigo-400 transition-colors">EXPERIENCE</a>
            <a href="#blogs" className="hover:text-indigo-400 transition-colors">ARTICLES</a>
            <a href="#whitepapers" className="hover:text-indigo-400 transition-colors">WHITEPAPERS</a>
            <a href="#podcasts" className="hover:text-indigo-400 transition-colors">PODCASTS</a>
          </div>

          {/* Enhanced Mobile Toggle Button */}
          <button 
            className="md:hidden w-8 h-8 relative focus:outline-none"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <div className={`absolute inset-0 flex items-center justify-center transition-transform duration-300 ${isMenuOpen ? 'rotate-180' : 'rotate-0'}`}>
              <div className="w-6 h-6 relative">
                <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${isMenuOpen ? 'rotate-45' : '-translate-y-2'}`}></span>
                <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${isMenuOpen ? 'opacity-0' : 'opacity-100'}`}></span>
                <span className={`absolute h-0.5 w-6 bg-white transform transition-all duration-300 ${isMenuOpen ? '-rotate-45' : 'translate-y-2'}`}></span>
              </div>
            </div>
          </button>
        </div>

        {/* Enhanced Mobile Menu Overlay */}
        <div 
          ref={menuRef}
          className={`md:hidden fixed inset-x-0 top-0 min-h-screen backdrop-blur-lg bg-gradient-to-b from-black/95 to-indigo-950/90 pt-20 pb-6 px-6 transform transition-opacity duration-300 ${isMenuOpen ? 'opacity-100' : 'opacity-0'}`}
        >
          <div className="flex flex-col space-y-8 text-center">
            <a 
              href="#projects" 
              className="text-xl font-light tracking-wide hover:text-indigo-400 transition-all duration-300 hover:tracking-wider"
              onClick={closeMenu}
            >
              PROJECTS
            </a>
            <a 
              href="#experience" 
              className="text-xl font-light tracking-wide hover:text-indigo-400 transition-all duration-300 hover:tracking-wider"
              onClick={closeMenu}
            >
              EXPERIENCE
            </a>
            <a 
              href="#blogs" 
              className="text-xl font-light tracking-wide hover:text-indigo-400 transition-all duration-300 hover:tracking-wider"
              onClick={closeMenu}
            >
              ARTICLES
            </a>
            <a 
              href="#whitepapers" 
              className="text-xl font-light tracking-wide hover:text-indigo-400 transition-all duration-300 hover:tracking-wider"
              onClick={closeMenu}
            >
              WHITEPAPERS
            </a>
            <a href="#podcasts" className="text-xl font-light tracking-wide hover:text-indigo-400 transition-all duration-300 hover:tracking-wider" onClick={closeMenu}>PODCASTS</a>
          </div>
        </div>
      </nav>

      {/* About Section */}
<section className="min-h-screen flex items-center justify-center relative bg-gray-900 text-white py-12">
  {/* Background Grid */}
  <div className="absolute inset-0 grid grid-cols-[repeat(10,1fr)] md:grid-cols-[repeat(20,1fr)] grid-rows-[repeat(10,1fr)] md:grid-rows-[repeat(20,1fr)] opacity-10">
    {[...Array(400)].map((_, i) => (
      <div 
        key={i} 
        className="border border-gray-800"
      />
    ))}
  </div>

  {/* Main Content */}
  <div className="flex flex-col md:flex-row items-center justify-center z-10 w-11/12 max-w-6xl mx-auto gap-8 md:gap-12">
    {/* Left Side: Image */}
    <div className="hidden md:flex md:flex-1 justify-center">
      <img 
        src="https://img.freepik.com/premium-vector/programming-home_118813-4357.jpg?semt=ais_hybrid" 
        alt="Mayank Sharma" 
        className="rounded-lg shadow-lg w-full max-w-md object-cover"
      />
    </div>

    {/* Right Side: Introduction */}
    <div className="md:flex-1 p-4 md:p-12 text-center md:text-left">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
        👋 Hi, I'm Mayank
      </h1>
      
      {/* Smaller Image for Mobile */}
      <div className="md:hidden mb-6">
        <img 
          src="https://img.freepik.com/premium-vector/programming-home_118813-4357.jpg?semt=ais_hybrid" 
          alt="Mayank Sharma" 
          className="rounded-lg shadow-lg w-full max-w-xs mx-auto"
        />
      </div>

      <p className="text-base md:text-lg lg:text-xl leading-relaxed">
        I'm a software engineer with experience at Intel, optimizing backend systems and building scalable solutions. I thrive on crafting innovative, user-focused solutions that blend creativity with technical expertise.
      </p>

      {/* Resume Button */}
      <div className="mt-6">
        <a 
          href="https://drive.google.com/file/d/1o_80wA_ivvPO_gjrwBc_UDe176d2jt4d/view?usp=sharing" // Replace with your actual resume link
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block px-6 py-3 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-600 transition-all"
        >
          View Resume
        </a>
      </div>

      <div className="mt-6">
        <span 
          ref={heroTextRef} 
          className="text-lg md:text-xl lg:text-2xl font-light tracking-widest block min-h-[2rem]"
        ></span>
      </div>
    </div>
  </div>
</section>


      {/* Work Section */}
      <div id="projects" className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
  <div className="absolute inset-0 -z-10 overflow-hidden">
    <svg
      aria-hidden="true"
      className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-700 [mask-image:radial-gradient(64rem_64rem_at_top,black,transparent)]"
    >
      <defs>
        <pattern
          x="50%"
          y={-1}
          id="e813992c-7d03-4cc4-a2bd-151760b470a0"
          width={200}
          height={200}
          patternUnits="userSpaceOnUse"
        >
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y={-1} className="overflow-visible fill-gray-800">
        <path
          d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
          strokeWidth={0}
        />
      </svg>
      <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
    </svg>
  </div>
  <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
    <div className="lg:pr-4">
      <div className="lg:max-w-lg">
        <p className="text-base/7 font-semibold text-indigo-400">Your Personal Library</p>
        <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-100 sm:text-5xl flex items-center">
          Kitaabkhana
          <a
            href="https://kitaabkhana.com"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2 text-indigo-400 hover:text-indigo-500"
            aria-label="Visit Kitaabkhana"
          >
            <ArrowTopRightOnSquareIcon className="h-6 w-6 inline" />
          </a>
        </h1>
        <p className="mt-6 text-xl/8 text-gray-300">
          Kitaabkhana is a web application designed to provide a platform for users to manage, share, and discover books.
        </p>
      </div>
    </div>
  </div>
  
  <div className="-ml-12 -mt-12 -mr-12 sm:mr-0 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden sm:p-6">
    <iframe
      src="https://www.youtube.com/embed/tb0fLr9KyZc?si=tFWFTnmvNvDOTR-j&autoplay=1&mute=1&loop=1&playlist=tb0fLr9KyZc&controls=0&rel=0&modestbranding=1"
      className="w-full h-[17rem] max-w-none rounded-xl bg-gray-800 shadow-xl ring-1 ring-gray-600/10 sm:w-full sm:h-[20rem] md:h-[25rem] lg:w-[48rem] lg:h-[30rem]"
      title="Looping Video Content"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      loading="lazy"
      frameBorder="0"
    ></iframe>
    
    {/* New Section for Documentation Blog */}
    <div className="mt-6">
      <h2 className="text-xl font-bold text-gray-100">Learn More in the Documentation</h2>
      <p className="text-gray-300 mt-2">
        Explore the detailed documentation blog to understand more about Kitaabkhana.
      </p>
      <div id="substack-feed-embed" />
    </div>
  </div>

  <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
    <div className="lg:pr-4">
      <div className="max-w-xl text-base/7 text-gray-300 lg:max-w-lg">
        <ul role="list" className="mt-8 space-y-8 text-gray-400">
          <li className="flex gap-x-3">
            <CloudArrowUpIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-400" />
            <span>
              <strong className="font-semibold text-gray-100">Upload your books.</strong> Upload all your books and manage them with kitaabkhana.
            </span>
          </li>
          <li className="flex gap-x-3">
            <LockClosedIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-400" />
            <span>
              <strong className="font-semibold text-gray-100">Privacy Assured.</strong> All your data and communities data is stored securely.
            </span>
          </li>
          <li className="flex gap-x-3">
            <ServerIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-400" />
            <span>
              <strong className="font-semibold text-gray-100">Read Anywhere.</strong> Upload once, read from any device.
            </span>
          </li>
        </ul>
        <p className="mt-8">
          <strong className="font-semibold text-gray-100">Technologies Used:</strong> Python, Flask, Supabase, Render, Tailwind, JavaScript
        </p>
        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-100">User Flow</h2>
        <img
          src="https://miro.medium.com/v2/resize:fit:1400/1*5e3WoGCaLCJ6ENi22dRzxw.png"
          alt="Kitaabkhana"
          className="w-full object-cover rounded-lg mb-4"
        />
      </div>
    </div>
  </div>
</div>

</div>
<div id="projects" className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
  <div className="absolute inset-0 -z-10 overflow-hidden">
    <svg
      aria-hidden="true"
      className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-700 [mask-image:radial-gradient(64rem_64rem_at_top,black,transparent)]"
    >
      <defs>
        <pattern
          x="50%"
          y={-1}
          id="e813992c-7d03-4cc4-a2bd-151760b470a0"
          width={200}
          height={200}
          patternUnits="userSpaceOnUse"
        >
          <path d="M100 200V.5M.5 .5H200" fill="none" />
        </pattern>
      </defs>
      <svg x="50%" y={-1} className="overflow-visible fill-gray-800">
        <path
          d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
          strokeWidth={0}
        />
      </svg>
      <rect fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" width="100%" height="100%" strokeWidth={0} />
    </svg>
  </div>
  <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
      <div className="lg:pr-4">
        <div className="lg:max-w-lg">
          <p className="text-base/7 font-semibold text-indigo-400">Don't just read, practice with notebook</p>
          <h1 className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-100 sm:text-5xl flex items-center">
          Notebook Converter
            <a
              href="https://historic-sybyl-mayanktech-f41d24f1.koyeb.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-indigo-400 hover:text-indigo-500"
              aria-label="Visit Notebook Converter"
            >
              <ArrowTopRightOnSquareIcon className="h-6 w-6 inline" />
            </a>
          </h1>
          <p className="mt-6 text-xl/8 text-gray-300">
          The Text-to-Jupyter Notebook Converter is an automated, cloud-enabled solution that leverages natural language processing (NLP) to convert unstructured text into structured, interactive Jupyter Notebooks. 
          </p>
        </div>
      </div>
    </div>
    <div className="-ml-12 -mt-12 -mr-12 sm:mr-0 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden sm:p-6">
    <iframe
      src="https://www.youtube.com/embed/j_dfHl_lUqE?si=IaQlqrPNDy6fJWrn&autoplay=1&mute=1&loop=1&playlist=j_dfHl_lUqE&controls=0&rel=0&modestbranding=1"
      className="w-full h-[17rem] max-w-none rounded-xl bg-gray-800 shadow-xl ring-1 ring-gray-600/10 sm:w-full sm:h-[20rem] md:h-[25rem] lg:w-[48rem] lg:h-[30rem]"
      title="Looping Video Content"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      loading="lazy"
      frameBorder="0"
    ></iframe>
    </div>

    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
      <div className="lg:pr-4">
        <div className="max-w-xl text-base/7 text-gray-300 lg:max-w-lg">
          <ul role="list" className="mt-8 space-y-8 text-gray-400">
            <li className="flex gap-x-3">
              <DocumentIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-400" />
              <span>
                <strong className="font-semibold text-gray-100">Upload files.</strong> Upload your text or md files with ease.
              </span>
            </li>
            <li className="flex gap-x-3">
              <ArrowPathIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-400" />
              <span>
                <strong className="font-semibold text-gray-100">Instant notebook converter.</strong> Convert them into Jupyter notebooks effortlessly.
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon aria-hidden="true" className="mt-1 size-5 flex-none text-indigo-400" />
              <span>
                <strong className="font-semibold text-gray-100">Ready to use.</strong> Download the completed notebooks instantly.
              </span>
            </li>
          </ul>
          <p className="mt-8">
            <strong className="font-semibold text-gray-100">Technologies Used:</strong> Flask, Google Gemini API, nbformat, Tailwind CSS, HTML, Python 
          </p>
          <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-100">Work Flow</h2>
          <img
            src="https://miro.medium.com/v2/resize:fit:828/format:webp/1*uOUAWsFThbijLUJ6FUQxgw.png"
            alt="Kitaabkhana"
            className="w-full object-cover rounded-lg mb-4"
          />
        </div>
      </div>
    </div>
  </div>
</div>





      {/* Experience Section */}
      <section id="experience" className="py-32 px-6 bg-gray-900">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl md:text-6xl font-bold mb-20 tracking-tight">Experience</h2>
          <div className="grid gap-12">
            {/* Tesco Media, Dunnhumby */}
            <div className="experience-item flex flex-col md:flex-row items-center md:items-start border-l-2 border-indigo-500 pl-8 relative">
              <div className="absolute left-[-9px] top-0 w-4 h-4 bg-indigo-500 rounded-full" />
              {/* Text */}
              <div className="flex-1 md:max-w-[60%]">
                <h3 className="text-2xl font-bold text-indigo-400">Tesco Media, Dunnhumby</h3>
                <p className="text-gray-400 mt-2">Engineer – Full Time (Nov’24 – Present)</p>
                <ul className="mt-4 space-y-2 text-gray-300">
                  <li>Architecting and implementing data processing infrastructure for retail media analytics.</li>
                  <li>Re-architected the canonical data service using Apache Beam on GCP Dataflow.</li>
                  <li>Building KPI Engine Service leveraging BigQuery's materialized views for sub-second query response times.</li>
                  <li>Tech Stack: Python, C#, .Net Core, GCP, Apache Beam, BigQuery, SpiceDB</li>
                </ul>
              </div>
              {/* Images */}
              <div className="flex-shrink-0 flex gap-4 mt-6 md:mb-0 md:ml-8">
                <img
                  src="A4BAD28F-327B-40A2-A42C-B69C400AB5CC.png" // Replace with the actual Tesco logo path
                  alt="Dunnhumby"
                  className="w-48 h-48 object-cover rounded-lg shadow-lg transform rotate-[-5deg]"
                />
                <img
                  src="EFCE810B-72FF-4DBE-8C80-5A9BCEF45BD6.png" // Replace with the actual Dunnhumby logo path
                  alt="Dunnhumby"
                  className="w-48 h-48 object-cover rounded-lg shadow-lg transform rotate-[5deg]"
                />
              </div>
            </div>

            {/* Intel Corporation */}
            <div className="experience-item flex flex-col md:flex-row items-center md:items-start border-l-2 border-indigo-500 pl-8 relative">
              <div className="absolute left-[-9px] top-0 w-4 h-4 bg-indigo-500 rounded-full" />
              {/* Text */}
              <div className="flex-1 md:max-w-[60%]">
                <h3 className="text-2xl font-bold text-indigo-400">Intel Corporation</h3>
                <p className="text-gray-400 mt-2">Software Engineer – Full Time (Jul’24 – Nov’24)</p>
                <ul className="mt-4 space-y-2 text-gray-300">
                  <li>Developed Intel Edge Orchestrator platform for managing cloud-native services at the edge.</li>
                  <li>Integrated monitoring and logging solutions for real-time performance insights.</li>
                  <li>Implemented K8sGPT for advanced diagnostics and real-time monitoring.</li>
                  <li>Tech Stack: Golang, Python, Docker, Kubernetes, RPC, Grafana</li>
                </ul>
              </div>
              {/* Images */}
              <div className="flex-shrink-0 flex gap-4 mt-6 md:mb-0 md:ml-8">
                <img
                  src="B606E698-92C5-4CEE-83B9-22971CE98C78.png" // Replace with the actual Intel logo path
                  alt="Intel Logo"
                  className="w-48 h-48 object-cover rounded-lg shadow-lg transform rotate-[-5deg]"
                />
                <img
                  src="D6B79229-DE45-4631-8DC9-88F469CC8C55.png" // Replace with the actual Intel Inside logo path
                  alt="Intel Inside Logo"
                  className="w-48 h-48 object-cover rounded-lg shadow-lg transform rotate-[5deg]"
                />
              </div>
            </div>

            {/* Progcap */}
            <div className="experience-item flex flex-col md:flex-row items-center md:items-start border-l-2 border-indigo-500 pl-8 relative">
              <div className="absolute left-[-9px] top-0 w-4 h-4 bg-indigo-500 rounded-full" />
              {/* Text */}
              <div className="flex-1 md:max-w-[60%]">
                <h3 className="text-2xl font-bold text-indigo-400">Progcap</h3>
                <p className="text-gray-400 mt-2">Software Engineer – Full Time (Aug’22 – Jul’24)</p>
                <ul className="mt-4 space-y-2 text-gray-300">
                  <li>Designed and implemented high-performance backend for an e-commerce platform.</li>
                  <li>Improved invoice parser accuracy from 62% to 85% using GPT-4o.</li>
                  <li>Built a unified notification system for personalized notifications across teams.</li>
                  <li>Tech Stack: Python, PostgreSQL, Redis, Celery, GPT-4o</li>
                </ul>
              </div>
              {/* Images */}
              <div className="flex-shrink-0 flex gap-4 mt-6 md:mb-0 md:ml-8">
                <img
                  src="1CAE97FD-3437-4B96-A19A-C932450B5982.png" // Replace with the actual Progcap logo path
                  alt="Progcap"
                  className="w-48 h-48 object-cover rounded-lg shadow-lg transform rotate-[-5deg]"
                />
                <img
                  src="1734942937804.jpeg" // Replace with the actual Progcap mission image path
                  alt="Progcap"
                  className="w-48 h-48 object-cover rounded-lg shadow-lg transform rotate-[5deg]"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section ref={sectionRef} id="blogs" className="bg-gray-900 py-20">
      <div className="max-w-6xl mx-auto px-6 lg:px-8">
        <div className="mb-16 blogs-heading">
          <h2 className="text-4xl font-extrabold text-white sm:text-5xl bg-clip-text text-white">
            Tech Articles 
          </h2>
          <p className="mt-4 text-lg text-gray-400">
            Collection of all the tech related articles I have posted over various platforms
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-10">
        {blogs.map((paper, index) => (
          <BlogsCard key={index} {...paper} />
        ))}
      </div>

      </div>
    </section>

    <section ref={sectionRef} id="whitepapers" className="bg-gray-900 py-20">
      <div className="max-w-6xl mx-auto px-6 lg:px-8">
        <div className="mb-16 blogs-heading">
          <h2 className="text-4xl font-extrabold text-white sm:text-5xl bg-clip-text text-white">
            Whitepapers 
          </h2>
          <p className="mt-4 text-lg text-gray-400">
            Collection of all the research papers I have written till now
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-10">
        {papers.map((paper, index) => (
          <ResearchPaperCard key={index} {...paper} />
        ))}
      </div>
      </div>
    </section>

    {/* Podcast Section */}
    <section id="podcasts" className="bg-gray-900 py-20">
      <div className="max-w-6xl mx-auto px-6 lg:px-8">
        <div className="mb-16">
          <h2 className="text-4xl font-extrabold text-white sm:text-5xl bg-clip-text text-white">
            Podcasts
          </h2>
          <p className="mt-4 text-lg text-gray-400">
            Explore my podcasts where I discuss technology, software engineering, and more.
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-10">
          {/* Podcast 1 */}
          <div className="p-6 bg-gradient-to-r from-gray-800 via-gray-900 to-black rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-300 transform max-w-md">
            <iframe src="https://www.youtube.com/embed/UOTb9F0-82A?si=8DKadX7ZigNUegQI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </section>

      

      {/* Contact Section */}
      <section id="contact" className="min-h-screen flex items-center justify-center relative">
        <div className="text-center">
          <h2 className="text-4xl md:text-7xl font-bold mb-8">Let's Connect</h2>
          <div className="space-x-8">
            <a href="https://www.linkedin.com/in/mayank-sharma-82ba20143/" className="text-xl hover:text-indigo-400 transition-colors">LINKEDIN</a>
            <a href="https://github.com/mayank8june" className="text-xl hover:text-indigo-400 transition-colors">GITHUB</a>
            <a href="mailto:mayank.sharma08june@gmail.com" className="text-xl hover:text-indigo-400 transition-colors">EMAIL</a>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-8 text-center text-gray-400 text-sm">
        <p>© 2024 Mayank Sharma. All rights reserved.</p>
      </footer>
    </div>
  );
};



export default AdvancedPortfolio;
